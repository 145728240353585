var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"form",attrs:{"slim":""}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('validation-provider',{attrs:{"name":"training name","rules":"required|max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Training Name *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter training name","trim":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"training type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Training Type *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"text","options":_vm.trainingType,"clearable":false,"placeholder":"Select training type"},model:{value:(_vm.form.trainingType),callback:function ($$v) {_vm.$set(_vm.form, "trainingType", $$v)},expression:"form.trainingType"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"provider","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Provider *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"name","multiple":"","options":_vm.providerName,"clearable":false,"placeholder":"Select provider name"},model:{value:(_vm.form.provider),callback:function ($$v) {_vm.$set(_vm.form, "provider", $$v)},expression:"form.provider"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"Start Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Start Date *","invalid-feedback":errors[0]}},[_c('date-picker',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Select start date","type":"date","valueType":"YYYY-MM-DD","value":_vm.formatDate(_vm.form.startDate),"format":"DD-MM-YYYY","editable":false,"clearable":false},model:{value:(_vm.form.startDate),callback:function ($$v) {_vm.$set(_vm.form, "startDate", $$v)},expression:"form.startDate"}})],1)]}}])})],1),_c('div',{staticClass:"col-lg-4"},[_c('validation-provider',{attrs:{"name":"End Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"End Date *","type":"date","rules":"required","invalid-feedback":errors[0]}},[_c('date-picker',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Select end date","type":"date","valueType":"YYYY-MM-DD","value":_vm.formatDate(_vm.form.endDate),"format":"DD-MM-YYYY","editable":false,"clearable":false},model:{value:(_vm.form.endDate),callback:function ($$v) {_vm.$set(_vm.form, "endDate", $$v)},expression:"form.endDate"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"certificate number","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Certificate Number *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter certificate number","trim":""},model:{value:(_vm.form.certificateNumber),callback:function ($$v) {_vm.$set(_vm.form, "certificateNumber", $$v)},expression:"form.certificateNumber"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"Certificate Expired"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Certificate Expired","type":"date","invalid-feedback":errors[0]}},[_c('date-picker',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Select certificated expired date","type":"date","valueType":"YYYY-MM-DD","value":_vm.formatDate(_vm.form.certificateExpired),"format":"DD-MM-YYYY","editable":false,"clearable":false},model:{value:(_vm.form.certificateExpired),callback:function ($$v) {_vm.$set(_vm.form, "certificateExpired", $$v)},expression:"form.certificateExpired"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"training context","rules":"required|max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Training Context *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"name","multiple":"","options":_vm.trainingContext,"clearable":false,"placeholder":"Select training context"},model:{value:(_vm.form.trainingContext),callback:function ($$v) {_vm.$set(_vm.form, "trainingContext", $$v)},expression:"form.trainingContext"}})],1)]}}])})],1),_c('div',{staticClass:"col-lg-4"},[_c('validation-provider',{attrs:{"name":"framework","rules":"max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Framework","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"name","multiple":"","options":_vm.framework,"clearable":false,"placeholder":"Select framework"},model:{value:(_vm.form.framework),callback:function ($$v) {_vm.$set(_vm.form, "framework", $$v)},expression:"form.framework"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"operating system","rules":"required|max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Operating System *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"name","multiple":"","options":_vm.operatingSystem,"clearable":false,"placeholder":"Select operating system"},model:{value:(_vm.form.operatingSystem),callback:function ($$v) {_vm.$set(_vm.form, "operatingSystem", $$v)},expression:"form.operatingSystem"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"platform","rules":"required|max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Platform *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"name","multiple":"","options":_vm.platform,"clearable":false,"placeholder":"Select platform"},model:{value:(_vm.form.platform),callback:function ($$v) {_vm.$set(_vm.form, "platform", $$v)},expression:"form.platform"}})],1)]}}])})],1)])]),_c('div',{staticClass:"row d-flex justify-content-end"},[_c('div',{staticClass:"mr-2 mb-2"},[_c('b-button',{staticClass:"next merge",attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.saveNewTraining}},[_vm._v(" Save ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }