<template>
    <b-card>
        <validation-observer ref="form" slim>
            <div class="row">
              <div class="col-lg-4">
                <validation-provider
                  name="training name"
                  rules="required|max:150"
                  v-slot="{ errors }"
                >
                  <b-form-group
                    label="Training Name *"
                    :invalid-feedback="errors[0]"
                  >
                    <b-form-input
                      placeholder="Enter training name"
                      v-model="form.name"
                      trim
                      :class="{ 'is-invalid': errors.length }"
                    />
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  name="training type"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group
                    label="Training Type *"
                    :invalid-feedback="errors[0]"
                  >
                    <custom-select
                      v-model="form.trainingType"
                      label="text"
                      :options="trainingType"
                      :clearable="false"
                      placeholder="Select training type"
                      :class="{ 'is-invalid': errors.length }"
                    />
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  name="provider"
                  rules="required|max:50"
                  v-slot="{ errors }"
                >
                  <b-form-group
                    label="Provider *"
                    :invalid-feedback="errors[0]"
                  >
                   <custom-select
                        v-model="form.provider"
                        label="name"
                        multiple
                        :options="providerName"
                        :clearable="false"
                        placeholder="Select provider name"
                        :class="{ 'is-invalid': errors.length }"
                      />
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  name="Start Date"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group
                    label="Start Date *"
                    :invalid-feedback="errors[0]"
                  >
                    <date-picker
                      placeholder="Select start date"
                      type="date"
                      valueType="YYYY-MM-DD"
                      :value="formatDate(form.startDate)"
                      format="DD-MM-YYYY"
                      :editable="false"
                      :clearable="false"
                      v-model="form.startDate"
                      :class="{ 'is-invalid': errors.length }">
                    </date-picker>
                  </b-form-group>
                </validation-provider>
              </div>
              <div class="col-lg-4">
                  <validation-provider
                    name="End Date"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-group
                      label="End Date *"
                      type="date"
                      rules="required"
                      :invalid-feedback="errors[0]"
                    >
                      <date-picker
                        placeholder="Select end date"
                        type="date"
                        valueType="YYYY-MM-DD"
                        :value="formatDate(form.endDate)"
                        format="DD-MM-YYYY"
                        :editable="false"
                        :clearable="false"
                        v-model="form.endDate"
                        :class="{ 'is-invalid': errors.length }">
                      </date-picker>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    name="certificate number"
                    rules="required|max:50"
                    v-slot="{ errors }"
                  >
                    <b-form-group
                      label="Certificate Number *"
                      :invalid-feedback="errors[0]"
                    >
                      <b-form-input
                        placeholder="Enter certificate number"
                        v-model="form.certificateNumber"
                        trim
                        :class="{ 'is-invalid': errors.length }"
                      />
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    name="Certificate Expired"
                    v-slot="{ errors }"
                  >
                    <b-form-group
                      label="Certificate Expired"
                      type="date"
                      :invalid-feedback="errors[0]"
                    >
                      <date-picker
                        placeholder="Select certificated expired date"
                        type="date"
                        valueType="YYYY-MM-DD"
                        :value="formatDate(form.certificateExpired)"
                        format="DD-MM-YYYY"
                        :editable="false"
                        :clearable="false"
                        v-model="form.certificateExpired"
                        :class="{ 'is-invalid': errors.length }">
                      </date-picker>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    name="training context"
                    rules="required|max:25"
                    v-slot="{ errors }"
                  >
                    <b-form-group
                      label="Training Context *"
                      :invalid-feedback="errors[0]"
                    >
                      <custom-select
                        v-model="form.trainingContext"
                        label="name"
                        multiple
                        :options="trainingContext"
                        :clearable="false"
                        placeholder="Select training context"
                        :class="{ 'is-invalid': errors.length }"
                      />
                    </b-form-group>
                  </validation-provider>
              </div>
              <div class="col-lg-4">
                <validation-provider
                  name="framework"
                  rules="max:25"
                  v-slot="{ errors }"
                >
                  <b-form-group
                    label="Framework"
                    :invalid-feedback="errors[0]"
                  >
                    <custom-select
                      v-model="form.framework"
                      label="name"
                      multiple
                      :options="framework"
                      :clearable="false"
                      placeholder="Select framework"
                      :class="{ 'is-invalid': errors.length }"
                    />
                  </b-form-group>
                </validation-provider>
                <validation-provider
                    name="operating system"
                    rules="required|max:25"
                    v-slot="{ errors }"
                  >
                    <b-form-group
                      label="Operating System *"
                      :invalid-feedback="errors[0]"
                    >
                      <custom-select
                        v-model="form.operatingSystem"
                        label="name"
                        multiple
                        :options="operatingSystem"
                        :clearable="false"
                        placeholder="Select operating system"
                        :class="{ 'is-invalid': errors.length }"
                      />
                  </b-form-group>
                </validation-provider>
                <validation-provider
                    name="platform"
                    rules="required|max:25"
                    v-slot="{ errors }"
                  >
                    <b-form-group
                      label="Platform *"
                      :invalid-feedback="errors[0]"
                    >
                      <custom-select
                        v-model="form.platform"
                        label="name"
                        multiple
                        :options="platform"
                        :clearable="false"
                        placeholder="Select platform"
                        :class="{ 'is-invalid': errors.length }"
                      />
                    </b-form-group>
                  </validation-provider>
              </div>
            </div>
          </validation-observer>
          <div class="row d-flex justify-content-end">
              <div class="mr-2 mb-2">
              <b-button
                  class="next merge"
                  type="submit"
                  variant="primary"
                  @click="saveNewTraining"
                  >
                  Save
              </b-button>
              </div>
          </div>
    </b-card>
</template>

<script>
import api from '@/api'
import moment from 'moment'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: { DatePicker },
  data() {
    return {
      form: {
        employeeId: '',
        name: '',
        startDate: '',
        endDate: '',
        certificateNumber: '',
        certificateExpired: '',
        trainingType: '',
        provider: [],
        trainingContext: [],
        operatingSystem: [],
        platform: [],
        framework: []
      },
      show: true,
      employee: '',
      trainingType: ['External', 'Internal'],
      providerName: [],
      trainingContext: [],
      framework: [],
      operatingSystem: [],
      platform: []
    }
  },
  created() {
    this.formatYear()
    this.fetchFramework()
    this.fetchPlatform()
    this.fetchOperatingSystem()
    this.fetchProvider()
    this.fetchTrainingContext()
    this.fetchEmployee()
  },

  methods: {
    formatDate(date) {
      var value = date === '' ? 'DD-MM-YYYY' : moment(date).format('YYYY-MM-DD')
      return value
    },
    formatYear() {
      this.form.certificateExpired = moment().add(1, 'd').format('YYYY-MM-DD')
      this.form.startDate = moment().subtract(5, 'years').format('YYYY-MM-DD')
      this.form.endDate = moment().subtract(5, 'years').format('YYYY-MM-DD')
    },
    async fetchFramework() {
      const { data } = await api.knowledge.framework()
      this.framework = data
    },
    async fetchPlatform() {
      const { data } = await api.knowledge.platform()
      this.platform = data
    },
    async fetchOperatingSystem() {
      const { data } = await api.knowledge.operatingSystem()
      this.operatingSystem = data
    },
    async fetchProvider() {
      const { data } = await api.training.providerName()
      this.providerName = data
    },
    async fetchTrainingContext() {
      const { data } = await api.training.trainingContext()
      this.trainingContext = data
    },
    async fetchEmployee() {
      const { data } = await api.employee.getUser(JSON.parse(localStorage.getItem('me')).id)
      this.employee = data.data.id
      const user = await api.employee.getById(data.data.id)
      this.employee = user.data.data
    },
    async saveNewTraining() {
      try {
        const valid = await this.$refs.form.validate()
        if (!valid) {
          this.isBusy = false
          this.$nextTick(() => {
            this.$bvToast.toast('Cannot save changes', {
              headerClass: 'd-none',
              solid: true,
              variant: 'danger'
            })
          })
          return
        }
        const tempProviderName = []
        for (let i = 0; i < this.form.provider.length; i++) {
          tempProviderName.push({
            id: this.form.provider[i].id,
            name: this.form.provider[i].name
          })
        }
        const tempPlatform = []
        for (let i = 0; i < this.form.platform.length; i++) {
          tempPlatform.push({
            id: this.form.platform[i].id,
            name: this.form.platform[i].name
          })
        }
        const tempFramework = []
        for (let i = 0; i < this.form.framework.length; i++) {
          tempFramework.push({
            id: this.form.framework[i].id,
            name: this.form.framework[i].name
          })
        }
        const tempOperatingSystem = []
        for (let i = 0; i < this.form.operatingSystem.length; i++) {
          tempOperatingSystem.push({
            id: this.form.operatingSystem[i].id,
            name: this.form.operatingSystem[i].name
          })
        }
        const tempTrainingContext = []
        for (let i = 0; i < this.form.trainingContext.length; i++) {
          tempTrainingContext.push({
            id: this.form.trainingContext[i].id,
            name: this.form.trainingContext[i].name
          })
        }
        const formSend = {
          employeeId: JSON.parse(localStorage.getItem('me')).employeeId,
          name: this.form.name,
          startDate: new Date(this.form.startDate),
          endDate: new Date(this.form.endDate),
          certificateNumber: this.form.certificateNumber,
          certificateExpired: new Date(this.form.certificateExpired),
          trainingType: this.form.trainingType,
          provider: tempProviderName,
          trainingContext: tempTrainingContext,
          operatingSystem: tempOperatingSystem,
          platform: tempPlatform,
          framework: tempFramework
        }
        await api.training.post(formSend)
        this.$nextTick(() => {
          setTimeout(() => {
            this.$bvToast.toast('Success save changes', {
              headerClass: 'd-none',
              solid: true,
              variant: 'success'
            })
          }, 500)
          localStorage.setItem('activeTab', 'Training')
          this.$router.push('/view-profile')
        })
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    }
  }
}
</script>
